{
  "netlify": {
    "capabilities": {
      "functions": {
        "invocations": {
          "included": 125000,
          "unit": "requests"
        },
        "runtime": {
          "included": 360000,
          "unit": "seconds"
        }
      }
    }
  }
}